<template>
  <div class="report-salary">
    <OutletSelect class="flex-grow-0" @change="onSelectOutlet"></OutletSelect>
    <KTPortlet class="mt-3 flex-grow-1" title="Chọn tháng">
      <template v-slot:toolbar>
        <div class="dowload-btn text-center" v-if="json_data.length">
          <export-excel
            class="d-inline-block"
            :data="json_data"
            :fields="json_fields"
            worksheet="My Worksheet"
            :name="file_name"
          >
            <div class="dowload-icon">
              <span class="export-pdf">Xuất file Excel</span>
            </div>
          </export-excel>
        </div>
      </template>

      <template v-slot:body>
        <div class="select-month" @click="onSelectMonth">
          <div class="select-month-content ml-5" v-if="valueMonthSelected">
            Tháng {{ valueMonthSelected }}
          </div>

          <div class="select-month-content ml-5" v-else>Chọn tháng...</div>
        </div>
        <div class="salary-table mt-3">
          <TableSalary
            :fieldsTable="fieldsTableOnline"
            :dataSalary="dataPreview"
            v-if="json_data.length"
          ></TableSalary>
        </div>
      </template>
    </KTPortlet>
    <!-- <div class="dowload-btn text-center mt-5" v-if="json_data.length">
      <export-excel
        class="d-inline-block"
        :data="json_data"
        :fields="json_fields"
        worksheet="My Worksheet"
        :name="file_name"
      >
        <div class="dowload-icon">
          <i class="flaticon-download"></i>
        </div>
        <span class="export-pdf">Xuất file Excel</span>
      </export-excel>
    </div> -->
    <b-modal
      v-model="modelMonth"
      responsive="true"
      centered
      hide-header
      hide-footer
      size="sm"
    >
      <TimePicker
        :itemList="listMonth"
        scrollerTitle="Chọn tháng"
        @selectedValue="selectedValue"
      ></TimePicker>
      <div class="d-flex">
        <b-button
          class="mr-4 w-50"
          variant="outline-secondary"
          @click="cancelSelect"
          >Hủy</b-button
        >
        <b-button class="w-50" variant="primary" @click="selectedMonth"
          >Đồng ý</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "./Salary.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import { Status, UserRole } from "./../../constans";
import Swal from "sweetalert2";
import axios from "@/common/api.js";
import Loader from "@/common/loader";
import vSelect from "vue-select";
import TimePicker from "@/components/time-picker";
import { getUserSalary } from "@/api/outlet";
import moment from "moment";
import TableSalary from "@/components/TableSalary.vue";

export default {
  name: "UserList",
  components: {
    KTPortlet,
    OutletSelect,
    vSelect,
    TimePicker,
    TableSalary
  },
  data() {
    return {
      json_fields: {},
      json_data: [],
      file_name: "",
      valueMonth: "",
      valueMonthSelected: "",
      modelMonth: false,
      listMonth: [
        {
          name: "Tháng 1",
          value: "1",
          selected: false,
          id: 1
        },
        {
          name: "Tháng 2",
          value: "2",
          selected: false,
          id: 2
        },
        {
          name: "Tháng 3",
          value: "3",
          selected: false,
          id: 3
        },
        {
          name: "Tháng 4",
          value: "4",
          selected: false,
          id: 4
        },
        {
          name: "Tháng 5",
          value: "5",
          selected: false,
          id: 5
        },
        {
          name: "Tháng 6",
          value: "6",
          selected: false,
          id: 6
        },
        {
          name: "Tháng 7",
          value: "7",
          selected: true,
          id: 7
        },
        {
          name: "Tháng 8",
          value: "8",
          selected: false,
          id: 8
        },
        {
          name: "Tháng 9",
          value: "9",
          selected: false,
          id: 9
        },
        {
          name: "Tháng 10",
          value: "10",
          selected: false,
          id: 10
        },
        {
          name: "Tháng 11",
          value: "11",
          selected: false,
          id: 11
        },
        {
          name: "Tháng 12",
          value: "12",
          selected: false,
          id: 12
        }
      ],
      fieldsTableOnline: [],
      dataPreview: []
    };
  },
  methods: {
    onSelectOutlet(event) {
      this.isUpdateUser = false;
      const params = {
        outlet: event
      };
      this.outletIdCurrent = event;
      this.$store.dispatch("updateListUsers");
      Loader.fire();
      this.$store.dispatch("getUsers", params).finally(Loader.close);
    },

    selectedValue(event) {
      this.valueMonth = event;
    },

    onSelectMonth() {
      this.modelMonth = true;
    },

    selectedMonth() {
      this.modelMonth = false;
      this.valueMonthSelected = this.valueMonth;
      Loader.fire();
      getUserSalary({
        outletId: this.outletIdCurrent,
        month: parseInt(this.valueMonthSelected)
      })
        .then(res => {
          this.json_data = res.jsonData;
          this.json_fields = res.jsonFields;
          this.file_name = res.fileName;
          let fieldTemp = {};
          this.dataPreview = this.json_data.slice(0, this.json_data.length - 5);
          let count = 0;
          for (let item in this.json_fields) {
            let oneItem = {
              key: this.json_fields[item],
              label: item,
              class: "text-nowrap"
            };
            this.fieldsTableOnline.push(oneItem);
          }
          this.fieldsTableOnline[0] = {
            ...this.fieldsTableOnline[0],
            ...{ stickyColumn: true, variant: "light" }
          };

          this.fieldsTableOnline.push(fieldTemp);
        })
        .finally(Loader.close);
    },

    cancelSelect() {
      this.modelMonth = false;
    },

    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    }
  },
  mounted() {
    this.onSelectOutlet("Tiệm 1");
    this.$store.dispatch("titleBreadcrumb", "Quản lý người dùng và quyền");
  },
  computed: {
    users() {
      return this.isUpdateUser
        ? this.dataUsersUpdate
        : this.$store.state.auth.listUser;
    }
  }
};
</script>
