<template>
  <div>
    <b-table
      :items="dataSalary"
      :fields="fieldsTable"
      responsive
      head-variant="light"
      :sticky-header="true"
      style="background: #fff;"
    ></b-table>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "SearchInput",
  data() {
    return {
      searchText: ""
    };
  },
  props: {
    fieldsTable: Array,
    dataSalary: Array
  },
  methods: {},
  computed: {}
};
</script>

<style></style>
